import HealthRecordCardTypeAbby from "../HealthRecordCardTypeAbby";
import {
  HealthRecordEntryType,
  HealthRecordEvent,
} from "../../services/core-api-adapter";
import HealthRecordCardTypeMedicalDiagnosis from "../HealthRecordCardTypeMedicalDiagnosis";
import HealthRecordCardTypeWhatsAppNurseChat from "../HealthRecordCardTypeWhatsAppNurseChat";
import HealthRecordCardTypeTelemedicineConsult from "../HealthRecordCardTypeTelemedicineConsult";
import HealthRecordCardTypeMedicineDispensed from "../HealthRecordCardTypeMedicineDispensed";
import HealthRecordCardTypeWhatsAppCounsellorChat from "../HealthRecordCardTypeWhatsAppCounsellorChat";
import { useTranslation } from "react-i18next";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
  Typography,
} from "@mui/material";
import Panel from "../Panel";
import { convertPxToRem, isObjectEmpty } from "../../utils";
import theme from "../../theme";
import IconLoader from "../IconLoader";
import HealthRecordCardTypeFaceScan from "../HealthRecordCardTypeFaceScan";
import HealthRecordCardTypeMedicalAssistance from "../HealthRecordCardTypeMedicalAssistance";

export interface DefaultHealthRecordCardTypeProps {
  hasDetails: boolean;
}

interface HealthRecordCardProps {
  data: HealthRecordEvent;
  onAccordionOpen: (type: string) => void;
}

interface HealthRecordCardTypeMapType {
  [key: string]: any;
}

const HealthRecordCardTypeMap: HealthRecordCardTypeMapType = {
  UNKNOWN: {
    component: null,
  },
  ABBY: {
    component: HealthRecordCardTypeAbby,
    color: theme.palette.primary.main,
    analyticsTrackingTypeName: "Abby",
  },
  FACE_SCAN: {
    component: HealthRecordCardTypeFaceScan,
    color: theme.palette.primary.main,
    analyticsTrackingTypeName: "BINAH",
  },
  MEDICAL_DIAGNOSIS: {
    component: HealthRecordCardTypeMedicalDiagnosis,
    color: theme.palette.secondary[700],
    analyticsTrackingTypeName: "ICD10",
  },
  WHATSAPP_NURSE_CHAT: {
    component: HealthRecordCardTypeWhatsAppNurseChat,
    color: "accent1.900",
    analyticsTrackingTypeName: "Nurse Consult",
  },
  TELEMEDICINE_CONSULT: {
    component: HealthRecordCardTypeTelemedicineConsult,
    color: "accent1.900",
    analyticsTrackingTypeName: "Nurse Consult",
  },
  WHATSAPP_COUNSELLOR_CHAT: {
    component: HealthRecordCardTypeWhatsAppCounsellorChat,
    color: "accent1.900",
    analyticsTrackingTypeName: "Nurse Consult",
  },
  MEDICINE_DISPENSED: {
    component: HealthRecordCardTypeMedicineDispensed,
    color: "accent1.500",
    analyticsTrackingTypeName: "NAPPI",
  },
  MEDICAL_ASSISTANCE: {
    component: HealthRecordCardTypeMedicalAssistance,
    color: "accent1.900",
    analyticsTrackingTypeName: "Medical assistance",
  },
  CONSULTATION: {
    component: HealthRecordCardTypeFaceScan,
    color: theme.palette.primary.main,
    analyticsTrackingTypeName: "Intercare",
  },
};

export const healthRecordCardLabelStyle = {
  fontSize: convertPxToRem(10),
  color: theme.palette.neutral[500],
  fontWeight: 600,
};

function HealthRecordCardPreview({
  dateTime,
  name,
  description,
  type,
}: {
  dateTime: string;
  name: string;
  description: string;
  type: HealthRecordEntryType;
}) {
  const { t } = useTranslation();

  function getCardDataBasedOnRecordEntryType(
    dataSourceType: HealthRecordEntryType
  ): { sourceValue: string; description: string } {
    if (dataSourceType === HealthRecordEntryType.FACE_SCAN) {
      return {
        sourceValue: t(`HealthRecordCardType.${dataSourceType}.sourceValue`),
        description: t(`HealthRecordCardType.${dataSourceType}.description`),
      };
    }

    if (dataSourceType === HealthRecordEntryType.ABBY) {
      return {
        sourceValue: t(`HealthRecordCardType.${dataSourceType}.sourceValue`),
        description: t(`HealthRecordCardType.${dataSourceType}.description`),
      };
    }

    if (dataSourceType === HealthRecordEntryType.SWEETLIFE_BOT) {
      return {
        sourceValue: t(`HealthRecordCardType.${dataSourceType}.sourceValue`),
        description: t(
          `HealthRecordCardType.${dataSourceType}.description.${description}` as any
        ),
      };
    }

    return {
      sourceValue: name,
      description: description,
    };
  }

  return (
    <Stack spacing={1} sx={{ width: "100%" }}>
      <Stack direction="row">
        <Stack sx={{ width: "50%" }} spacing={1}>
          <Typography variant="subtitle2">
            {t(`HealthRecordCardType.${type}.source` as any)}
          </Typography>
          <Typography variant="h4">
            {getCardDataBasedOnRecordEntryType(type).sourceValue}
          </Typography>
        </Stack>
        <Stack sx={{ width: "50%" }} spacing={1}>
          <Typography variant="subtitle2">
            {t("HealthRecordCardType.Default.dateLabel")}
          </Typography>
          <Typography variant="h4">
            {new Date(dateTime)?.toISOString()?.split("T")[0]}
          </Typography>
        </Stack>
      </Stack>
      <Stack spacing={1}>
        <Typography variant="subtitle2">
          {t("HealthRecordCardType.Default.descriptionLabel")}
        </Typography>
        <Typography variant="h4">
          {getCardDataBasedOnRecordEntryType(type).description}
        </Typography>
      </Stack>
    </Stack>
  );
}

function isHealthRecordHasDetails(type: HealthRecordEntryType, details: any) {
  switch (type) {
    case HealthRecordEntryType.ABBY:
      return details?.data && details.data?.length > 0;
    case HealthRecordEntryType.MEDICAL_DIAGNOSIS:
      return details?.procedure;
    case HealthRecordEntryType.WHATSAPP_NURSE_CHAT:
      return Boolean(details?.caption);
    case HealthRecordEntryType.TELEMEDICINE_CONSULT:
      return Boolean(details?.caption);
    case HealthRecordEntryType.WHATSAPP_COUNSELLOR_CHAT:
      return Boolean(details?.caption);
    case HealthRecordEntryType.FACE_SCAN:
      return Boolean(details?.caption);
    case HealthRecordEntryType.MEDICINE_DISPENSED:
      return Boolean(details?.prescription);
    case HealthRecordEntryType.MEDICAL_ASSISTANCE:
      return !isObjectEmpty(details);
    case HealthRecordEntryType.CONSULTATION:
      return Boolean(details?.caption);
  }
}

export default function HealthRecordCard({
  data,
  onAccordionOpen,
}: HealthRecordCardProps) {
  const { t } = useTranslation();
  const healthRecordCardType =
    HealthRecordCardTypeMap[data.type] || HealthRecordCardTypeMap.UNKNOWN;
  const hasDetails = isHealthRecordHasDetails(data.type, data.details);

  function onAccordionChange(_: any, expanded: boolean) {
    if (expanded) {
      return onAccordionOpen(healthRecordCardType.analyticsTrackingTypeName);
    }
  }

  const shouldShowFooter =
    data.type !== HealthRecordEntryType.MEDICAL_ASSISTANCE;

  return (
    <Panel accentColour={healthRecordCardType.color} padding={0}>
      <Stack
        sx={{
          overflow: "hidden",
        }}
      >
        <Accordion
          disableGutters={true}
          onChange={onAccordionChange}
          sx={{
            ".MuiButtonBase-root": {
              alignItems: "flex-start",
            },
            ".MuiAccordionSummary-expandIconWrapper": {
              mt: 2,
              mr: 2,
            },
            ".MuiAccordionSummary-root": {
              p: 0,
            },
            ".MuiAccordionSummary-content": {
              m: 0,
              p: 2,
            },
          }}
        >
          <AccordionSummary
            expandIcon={
              hasDetails ? (
                <IconLoader icon="ChevronDownIcon" />
              ) : (
                <IconLoader icon="ChevronDownIcon" sx={{ opacity: 0 }} />
              )
            }
          >
            <HealthRecordCardPreview
              dateTime={data.dateTime}
              description={data.description}
              name={data.name}
              type={data.type}
            />
          </AccordionSummary>
          {hasDetails && (
            <AccordionDetails>
              <Stack spacing={2}>
                {healthRecordCardType.component({
                  data: data,
                  hasDetails: hasDetails,
                })}
                {shouldShowFooter && (
                  <Stack direction={"row"}>
                    <Stack sx={{ width: "50%" }} spacing={1}>
                      <Typography variant="subtitle2">
                        {t("HealthRecordCardType.Default.dateLabel")}:
                      </Typography>
                      <Typography variant="body2">
                        {new Date(data.dateTime)?.toISOString()?.split("T")[0]}
                      </Typography>
                    </Stack>
                    <Stack spacing={1}>
                      <Typography variant="subtitle2">
                        {t("HealthRecordCardType.Default.timeLabel")}:
                      </Typography>
                      <Typography variant="body2">
                        {new Date(data.dateTime).toLocaleTimeString()}
                      </Typography>
                    </Stack>
                  </Stack>
                )}
              </Stack>
            </AccordionDetails>
          )}
        </Accordion>
      </Stack>
    </Panel>
  );
}
