import { Stack, Typography } from "@mui/material";
import HealthRecordCard from ".";
import { HealthRecordEntryType } from "../../services/core-api-adapter";

const AbbyData = {
  id: "c0e852fc-ba70-4308-a779-8fcf92a922f0",
  type: HealthRecordEntryType.ABBY,
  name: "Abby Health Station",
  description: "You visited an Abby Health Station",
  dateTime: "2022-11-03T13:05:25.140873+00:00",
  details: {
    data: [
      {
        type: "BodyMassIndex",
        metricType: "kg/m²",
        value: "25.83",
      },
      {
        type: "DiastolicBloodPressure",
        metricType: "mm/Hg",
        value: "95.1",
      },
      {
        type: "Height",
        metricType: "CM",
        value: "177.1",
      },
      {
        type: "SmokingStatus",
        metricType: "Yes/No",
        value: "No",
      },
      {
        type: "SystolicBloodPressure",
        metricType: "mm/Hg",
        value: "82.3",
      },
      {
        type: "Weight",
        metricType: "KG",
        value: "82.7",
      },
    ],
  },
};
const AbbyEmptyData = {
  id: "c0e852fc-ba70-4308-a779-8fcf92a922f0",
  type: HealthRecordEntryType.ABBY,
  name: "Abby Health Station",
  description: "You visited an Abby Health Station",
  dateTime: "2022-11-03T13:05:25.140873+00:00",
  details: {
    data: [],
  },
};
const icd10Data = {
  id: "c0e852fc-ba70-4308-a779-8fcf92a922f0",
  type: HealthRecordEntryType.MEDICAL_DIAGNOSIS,
  name: "L50.9",
  description: "Urticaria",
  dateTime: "2022-11-03T13:05:25.140873+00:00",
  details: {
    procedure: [
      {
        name: "ANAESTHETIC ASSESSMENT",
        items: [
          {
            name: "Common Claim Code",
            value: "151",
          },
          {
            name: "Practice speciality code",
            value: "10",
          },
        ],
      },
      {
        name: "ORTHOPAEDIC MODIFIER",
        items: [
          {
            name: "Common Claim Code",
            value: "151",
          },
          {
            name: "Practice speciality code",
            value: "10",
          },
        ],
      },
      {
        name: "ANAESTHETIC TIME X60 MIN",
        items: [
          {
            name: "Common Claim Code",
            value: "151",
          },
          {
            name: "Practice speciality code",
            value: "10",
          },
        ],
      },
      {
        name: "PRONE POSITION",
        items: [
          {
            name: "Common Claim Code",
            value: "151",
          },
          {
            name: "Practice speciality code",
            value: "10",
          },
        ],
      },
      {
        name: "BLOOD PRESSURE CONTROL X30 MIN",
        items: [
          {
            name: "Common Claim Code",
            value: "151",
          },
          {
            name: "Practice speciality code",
            value: "10",
          },
        ],
      },
    ],
    practiceName: "Celliersstraat narkose dienste",
    treatmentLocation: "Not Specified",
  },
};

const icd10EmptyDetailsData = {
  id: "c0e852fc-ba70-4308-a779-8fcf92a922f0",
  type: HealthRecordEntryType.MEDICAL_DIAGNOSIS,
  name: "L50.9",
  description: "Urticaria",
  dateTime: "2022-11-03T13:05:25.140873+00:00",
  details: {},
};

const icd10EmptyProcedureItemsData = {
  id: "c0e852fc-ba70-4308-a779-8fcf92a922f0",
  type: HealthRecordEntryType.MEDICAL_DIAGNOSIS,
  name: "L50.9",
  description: "Urticaria",
  dateTime: "2022-11-03T13:05:25.140873+00:00",
  details: {
    procedure: [
      {
        name: "ANAESTHETIC ASSESSMENT",
        items: [],
      },
      {
        name: "ORTHOPAEDIC MODIFIER",
        items: [
          {
            name: "Common Claim Code",
            value: "151",
          },
          {
            name: "Practice speciality code",
            value: "10",
          },
        ],
      },
      {
        name: "ANAESTHETIC TIME X60 MIN",
        items: [],
      },
      {
        name: "PRONE POSITION",
        items: [
          {
            name: "Common Claim Code",
            value: "151",
          },
          {
            name: "Practice speciality code",
            value: "10",
          },
        ],
      },
      {
        name: "BLOOD PRESSURE CONTROL X30 MIN",
        items: [],
      },
    ],
    practiceName: "Celliersstraat narkose dienste",
    treatmentLocation: "Not Specified",
  },
};
const icd10NoSpecifiedItemsData = {
  id: "c0e852fc-ba70-4308-a779-8fcf92a922f0",
  type: HealthRecordEntryType.MEDICAL_DIAGNOSIS,
  name: "L50.9",
  description: "Urticaria",
  dateTime: "2022-11-03T13:05:25.140873+00:00",
  details: {
    procedure: [],
    practiceName: null,
    treatmentLocation: "Not Specified",
  },
};

const nappiData = {
  id: "c0e852fc-ba70-4308-a779-8fcf92a922f0",
  type: HealthRecordEntryType.MEDICINE_DISPENSED,
  name: "827215002",
  description: "DERMATOLOGICALS - Acne (18.4.1)",
  dateTime: "2022-11-03T13:05:25.140873+00:00",
  details: {
    prescription: "Differin",
  },
};
const nappiEmptyData = {
  id: "c0e852fc-ba70-4308-a779-8fcf92a922f0",
  type: HealthRecordEntryType.MEDICINE_DISPENSED,
  name: "827215002",
  description: "DERMATOLOGICALS - Acne (18.4.1)",
  dateTime: "2022-11-03T13:05:25.140873+00:00",
  details: {},
};

const counsellourChatData = {
  id: "19223d4a-69a6-457f-aaf9-e37569821c56",
  type: HealthRecordEntryType.WHATSAPP_COUNSELLOR_CHAT,
  name: "WhatsApp Counsellor Chat",
  description: "Telemedicine counsellor consultation",
  dateTime: "2022-12-02T12:52:58.088482+00:00",
  details: {
    caption: "You started a counsellor chat",
  },
};
const counsellourChatEmptyData = {
  id: "19223d4a-69a6-457f-aaf9-e37569821c56",
  type: HealthRecordEntryType.WHATSAPP_COUNSELLOR_CHAT,
  name: "WhatsApp Counsellor Chat",
  description: "Telemedicine counsellor consultation",
  dateTime: "2022-12-02T12:52:58.088482+00:00",
  details: {},
};

const nurseChatData = {
  id: "c0e852fc-ba70-4308-a779-8fcf92a922f0",
  type: HealthRecordEntryType.WHATSAPP_NURSE_CHAT,
  name: "WhatsApp Nurse Chat",
  description: "Telemedicine consultation",
  dateTime: "2022-11-03T13:05:25.140873+00:00",
  details: {
    caption: "You started a Nurse chat",
  },
};
const nurseChatEmptyData = {
  id: "c0e852fc-ba70-4308-a779-8fcf92a922f0",
  type: HealthRecordEntryType.WHATSAPP_NURSE_CHAT,
  name: "WhatsApp Nurse Chat",
  description: "Telemedicine consultation",
  dateTime: "2022-11-03T13:05:25.140873+00:00",
  details: {},
};

const HaveANurseCallMeData = {
  id: "83f7e970-4729-4708-84ef-c512b771ea28",
  type: HealthRecordEntryType.TELEMEDICINE_CONSULT,
  name: "Telemedicine consult",
  description: "Telemedicine Nurse consultation",
  dateTime: "2023-07-19T14:40:00.406362+00:00",
  details: {
    caption: "You started a telemedicine consult.",
  },
};
const HaveANurseCallMeEmptyData = {
  id: "83f7e970-4729-4708-84ef-c512b771ea28",
  type: HealthRecordEntryType.TELEMEDICINE_CONSULT,
  name: "Telemedicine consult",
  description: "Telemedicine Nurse consultation",
  dateTime: "2023-07-19T14:40:00.406362+00:00",
  details: {},
};

const BinahFaceScanData = {
  id: "f2e06c26-b10b-4c86-bdf3-7083f48e7d04",
  type: HealthRecordEntryType.FACE_SCAN,
  name: "BINAH",
  description: "Binah face scan",
  dateTime: "2023-07-20T09:03:50.579648+00:00",
  details: {},
};

const MedicalAssistanceSelfCareData = {
  id: "c0e852fc-ba70-4308-a779-8fcf92a922f0",
  type: HealthRecordEntryType.MEDICAL_ASSISTANCE,
  name: "Medical assistance",
  description: "Health Check, Symptom checker",
  dateTime: "2022-11-03T13:05:25.140873+00:00",
  details: {
    recommendation: {
      name: "RECOMMENDATION",
      items: [
        {
          name: "Self care",
        },
        {
          name: "Online Doctor consultation",
        },
        {
          name: "Online Nurse consultation",
        },
      ],
    },
    possibleConditions: {
      name: "POSSIBLE CONDITIONS",
      items: [
        {
          name: "Common cold",
          value: "https://www.google.com/",
        },
        {
          name: "Hay fever",
          value: "https://www.google.com/",
        },
        {
          name: "Allergies",
          value: "https://www.google.com/",
        },
      ],
    },
    consultationOutcome: {
      name: "Consultation outcome",
      items: [
        {
          name: "Self care",
        },
      ],
    },
    rating: {
      name: "Rating",
      items: [
        {
          name: "4 stars",
        },
      ],
    },
    more: {
      name: "More",
      location: "Pharmacy location search",
      items: [
        {
          name: "Ticket open",
          value: "2022-11-03T13:05:25.140873+00:00",
        },
        {
          name: "Ticket closed",
          value: "2022-11-03T13:05:25.140873+00:00",
        },
      ],
    },
  },
};

const MedicalAssistanceOnlineDoctorConsultationData = {
  id: "c0e852fc-ba70-4308-a779-8fcf92a922f0",
  type: HealthRecordEntryType.MEDICAL_ASSISTANCE,
  name: "Medical assistance",
  description: "Health Check, Symptom checker",
  dateTime: "2022-11-03T13:05:25.140873+00:00",
  details: {
    recommendation: {
      name: "RECOMMENDATION",
      items: [
        {
          name: "Online Doctor consultation",
        },
        {
          name: "Online Nurse consultation",
        },
      ],
    },
    possibleConditions: {
      name: "POSSIBLE CONDITIONS",
      items: [
        {
          name: "Back strain",
          value: "https://www.google.com/",
        },
        {
          name: "Influenza (Flu)",
          value: "https://www.google.com/",
        },
        {
          name: "Strep Throat",
          value: "https://www.google.com/",
        },
        {
          name: "Stomach flu (gastroenteritis)",
          value: "https://www.google.com/",
        },
      ],
    },
    consultationOutcome: {
      name: "Consultation outcome",
      items: [
        {
          name: "Online Doctor consultation",
          value: "R180",
        },
      ],
    },
    paymentMethod: {
      name: "Payment method",
      items: [
        {
          name: "Card payment",
          value: "5678 1******* 1234",
        },
      ],
    },
    outcomeStatus: {
      name: "Outcome status",
      items: [
        {
          name: "Online consultation",
          value: "Successful",
        },
      ],
    },
    faceScanResults: {
      name: "Face scan results",
      items: [
        {
          name: "Results",
          value: "https://www.google.com/",
        },
      ],
    },
    rating: {
      name: "Rating",
      items: [
        {
          name: "4 stars",
        },
      ],
    },
    more: {
      name: "More",
      location: "Intercare Southdowns",
      items: [
        {
          name: "Ticket open",
          value: "2022-11-03T13:05:25.140873+00:00",
        },
        {
          name: "Ticket closed",
          value: "2022-11-03T13:05:25.140873+00:00",
        },
      ],
    },
  },
};

const MedicalAssistanceOnlineNurseConsultationData = {
  id: "c0e852fc-ba70-4308-a779-8fcf92a922f0",
  type: HealthRecordEntryType.MEDICAL_ASSISTANCE,
  name: "Medical assistance",
  description: "Health Check, Symptom checker",
  dateTime: "2022-11-03T13:05:25.140873+00:00",
  details: {
    recommendation: {
      name: "RECOMMENDATION",
      items: [
        {
          name: "Online Nurse consultation",
        },
      ],
    },
    possibleConditions: {
      name: "POSSIBLE CONDITIONS",
      items: [
        {
          name: "Back strain",
          value: "https://www.google.com/",
        },
        {
          name: "Influenza (Flu)",
          value: "https://www.google.com/",
        },
        {
          name: "Strep Throat",
          value: "https://www.google.com/",
        },
        {
          name: "Stomach flu (gastroenteritis)",
          value: "https://www.google.com/",
        },
      ],
    },
    consultationOutcome: {
      name: "Consultation outcome",
      items: [
        {
          name: "Online Nurse consultation",
          value: "R180",
        },
      ],
    },
    paymentMethod: {
      name: "Payment method",
      items: [
        {
          name: "Card payment",
          value: "5678 1******* 1234",
        },
      ],
    },
    outcomeStatus: {
      name: "Outcome status",
      items: [
        {
          name: "Online consultation",
          value: "Successful",
        },
      ],
    },
    faceScanResults: {
      name: "Face scan results",
      items: [
        {
          name: "Results",
          value: "https://www.google.com/",
        },
      ],
    },
    rating: {
      name: "Rating",
      items: [
        {
          name: "4 stars",
        },
      ],
    },
    more: {
      name: "More",
      location: "Intercare Southdowns",
      items: [
        {
          name: "Ticket open",
          value: "2022-11-03T13:05:25.140873+00:00",
        },
        {
          name: "Ticket closed",
          value: "2022-11-03T13:05:25.140873+00:00",
        },
      ],
    },
  },
};

const MedicalAssistanceInPersonDoctorConsultationData = {
  id: "c0e852fc-ba70-4308-a779-8fcf92a922f0",
  type: HealthRecordEntryType.MEDICAL_ASSISTANCE,
  name: "Medical assistance",
  description: "Health Check, Symptom checker",
  dateTime: "2022-11-03T13:05:25.140873+00:00",
  details: {
    recommendation: {
      name: "RECOMMENDATION",
      items: [
        {
          name: "In-person Doctor consultation",
        },
        {
          name: "In-person Nurse consultation",
        },
      ],
    },
    possibleConditions: {
      name: "POSSIBLE CONDITIONS",
      items: [
        {
          name: "Back strain",
          value: "https://www.google.com/",
        },
        {
          name: "Influenza (Flu)",
          value: "https://www.google.com/",
        },
        {
          name: "Strep Throat",
          value: "https://www.google.com/",
        },
        {
          name: "Stomach flu (gastroenteritis)",
          value: "https://www.google.com/",
        },
      ],
    },
    consultationOutcome: {
      name: "Consultation outcome",
      items: [
        {
          name: "In-person Doctor consultation",
          value: "R500",
        },
      ],
    },
    outcomeStatus: {
      name: "Outcome status",
      items: [
        {
          name: "In-person Doctor consultation",
          value: "Successful",
        },
      ],
    },
    rating: {
      name: "Rating",
      items: [
        {
          name: "4 stars",
        },
      ],
    },
    more: {
      name: "More",
      location: "Intercare Southdowns",
      items: [
        {
          name: "Ticket open",
          value: "2022-11-03T13:05:25.140873+00:00",
        },
        {
          name: "Ticket closed",
          value: "2022-11-03T13:05:25.140873+00:00",
        },
      ],
    },
  },
};

const MedicalAssistanceNoDetails = {
  id: "c0e852fc-ba70-4308-a779-8fcf92a922f0",
  type: HealthRecordEntryType.MEDICAL_ASSISTANCE,
  name: "Medical assistance",
  description: "Health Check, Symptom checker",
  dateTime: "2022-11-03T13:05:25.140873+00:00",
  details: {
    possibleConditions: {
      name: "POSSIBLE CONDITIONS",
      items: [],
    },
    consultationOutcome: {
      name: "Consultation outcome",
      items: [],
    },
    rating: {
      name: "Rating",
      items: [],
    },
    more: {
      name: "More",
      location: "Pharmacy location search",
      items: [],
    },
  },
};

const MedicalAssistanceNoData = {
  id: "c0e852fc-ba70-4308-a779-8fcf92a922f0",
  type: HealthRecordEntryType.MEDICAL_ASSISTANCE,
  name: "Medical assistance",
  description: "Health Check, Symptom checker",
  dateTime: "2022-11-03T13:05:25.140873+00:00",
  details: {},
};

export default function HealthRecordCardExamples() {
  function onAccordionOpen() {
    console.log("accordion opened");
  }
  return (
    <Stack p={2} spacing={2}>
      <Typography variant="h2" mb={1}>
        Abby results
      </Typography>
      <Stack>
        <Typography variant="h4" mb={1}>
          Abby result with data
        </Typography>
        <HealthRecordCard data={AbbyData} onAccordionOpen={onAccordionOpen} />
      </Stack>
      <Stack>
        <Typography variant="h4" mb={1}>
          Abby result with no data
        </Typography>
        <HealthRecordCard
          data={AbbyEmptyData}
          onAccordionOpen={onAccordionOpen}
        />
      </Stack>

      <Typography variant="h2">ICD10 Diagnosis</Typography>
      <Stack>
        <Typography variant="h4" mb={1}>
          ICD10 Diagnosis with data
        </Typography>
        <HealthRecordCard onAccordionOpen={onAccordionOpen} data={icd10Data} />
      </Stack>
      <Stack>
        <Typography variant="h4" mb={1}>
          ICD10 Diagnosis with no details data
        </Typography>
        <HealthRecordCard
          onAccordionOpen={onAccordionOpen}
          data={icd10EmptyDetailsData}
        />
      </Stack>
      <Stack>
        <Typography variant="h4" mb={1}>
          ICD10 Diagnosis with no procedure item data
        </Typography>
        <HealthRecordCard
          onAccordionOpen={onAccordionOpen}
          data={icd10EmptyProcedureItemsData}
        />
      </Stack>
      <Stack>
        <Typography variant="h4" mb={1}>
          ICD10 Diagnosis with no specified item data
        </Typography>
        <HealthRecordCard
          onAccordionOpen={onAccordionOpen}
          data={icd10NoSpecifiedItemsData}
        />
      </Stack>

      <Typography variant="h2">Nappi Diagnosis</Typography>
      <Stack mb={1}>
        <Typography variant="h4" mb={1}>
          Nappi diagnosis with data
        </Typography>
        <HealthRecordCard data={nappiData} onAccordionOpen={onAccordionOpen} />
      </Stack>
      <Stack mb={1}>
        <Typography variant="h4" mb={1}>
          Nappi diagnosis with no data
        </Typography>
        <HealthRecordCard
          data={nappiEmptyData}
          onAccordionOpen={onAccordionOpen}
        />
      </Stack>

      <Typography variant="h2">Counsellour chat</Typography>
      <Stack mb={1}>
        <Typography variant="h4" mb={1}>
          Counsellour chat with data
        </Typography>
        <HealthRecordCard
          data={counsellourChatData}
          onAccordionOpen={onAccordionOpen}
        />
      </Stack>
      <Stack mb={1}>
        <Typography variant="h4" mb={1}>
          Counsellour chat with no data
        </Typography>
        <HealthRecordCard
          data={counsellourChatEmptyData}
          onAccordionOpen={onAccordionOpen}
        />
      </Stack>

      <Typography variant="h2">Nurse chat</Typography>
      <Stack mb={1}>
        <Typography variant="h4" mb={1}>
          Nurse chat with data
        </Typography>
        <HealthRecordCard
          data={nurseChatData}
          onAccordionOpen={onAccordionOpen}
        />
      </Stack>
      <Stack mb={1}>
        <Typography variant="h4" mb={1}>
          Nurse chat with no data
        </Typography>
        <HealthRecordCard
          data={nurseChatEmptyData}
          onAccordionOpen={onAccordionOpen}
        />
      </Stack>

      <Typography variant="h2">Nurse chat(Have a Nurse call me)</Typography>
      <Stack mb={1}>
        <Typography variant="h4" mb={1}>
          Nurse chat with data
        </Typography>
        <HealthRecordCard
          data={HaveANurseCallMeData}
          onAccordionOpen={onAccordionOpen}
        />
      </Stack>
      <Stack mb={1}>
        <Typography variant="h4" mb={1}>
          Nurse chat with no data
        </Typography>
        <HealthRecordCard
          data={HaveANurseCallMeEmptyData}
          onAccordionOpen={onAccordionOpen}
        />
      </Stack>

      <Typography variant="h2">Binah face scan</Typography>
      <Stack mb={1}>
        <Typography variant="h4" mb={1}>
          Binah face scan with no data
        </Typography>
        <HealthRecordCard
          data={BinahFaceScanData}
          onAccordionOpen={onAccordionOpen}
        />
      </Stack>

      <Typography variant="h2">Medical assistance</Typography>
      <Stack mb={1}>
        <Typography variant="h4" mb={1}>
          Medical assistance self care data
        </Typography>
        <HealthRecordCard
          data={MedicalAssistanceSelfCareData}
          onAccordionOpen={onAccordionOpen}
        />
      </Stack>

      <Stack mb={1}>
        <Typography variant="h4" mb={1}>
          Medical assistance Online Doctor consultation data
        </Typography>
        <HealthRecordCard
          data={MedicalAssistanceOnlineDoctorConsultationData}
          onAccordionOpen={onAccordionOpen}
        />
      </Stack>

      <Stack mb={1}>
        <Typography variant="h4" mb={1}>
          Medical assistance Online Nurse consultation data
        </Typography>
        <HealthRecordCard
          data={MedicalAssistanceOnlineNurseConsultationData}
          onAccordionOpen={onAccordionOpen}
        />
      </Stack>

      <Stack mb={1}>
        <Typography variant="h4" mb={1}>
          Medical assistance In-person Doctor consultation data
        </Typography>
        <HealthRecordCard
          data={MedicalAssistanceInPersonDoctorConsultationData}
          onAccordionOpen={onAccordionOpen}
        />
      </Stack>

      <Stack mb={1}>
        <Typography variant="h4" mb={1}>
          Medical assistance with no details
        </Typography>
        <HealthRecordCard
          data={MedicalAssistanceNoDetails}
          onAccordionOpen={onAccordionOpen}
        />
      </Stack>

      <Stack mb={1}>
        <Typography variant="h4" mb={1}>
          Medical assistance with no data at all
        </Typography>
        <HealthRecordCard
          data={MedicalAssistanceNoData}
          onAccordionOpen={onAccordionOpen}
        />
      </Stack>
    </Stack>
  );
}
